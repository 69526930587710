<template>
    <div class="main-container">
        <div class="form-wrapper">
            <p class="title">Formulario de registro</p>
            <p class="subtitle">Acceso a la comunidad de inversión</p>
            <div class="username-form-wrapper">
                <span class="">Usuario</span>
                <div class="input-wrapper">
                    <span class="material-symbols-outlined">person</span>
                    <input id="username-input" v-model="user_name" name="user_name" class="username-style" type="text" placeholder="p.ej. abcdefg">
                </div>
            </div>
            <div class="password-form-wrapper">
                <span class="">Contraseña</span>
                <div class="input-wrapper">
                    <span class="material-symbols-outlined">lock</span>
                    <input id="password-input" v-model="user_password" name="user_password" class="password-style" type="password">
                </div>
            </div>
            <div class="email-form-wrapper">
                <span class="">Email</span>
                <div class="input-wrapper">
                    <span class="material-symbols-outlined">mail</span>
                    <input id="email-input" v-model="user_email" name="user_email" class="email-style" type="email" placeholder="p.ej. xyz@gmail.com">
                </div>
            </div>
            <div class="phone-number-form-wrapper">
                <span class="">Teléfono</span>
                <div class="input-wrapper">
                    <span class="material-symbols-outlined">call</span>
                    <input id="phone-input" v-model="user_phone" name="user_phone" class="phone-style" type="tel" placeholder="p.ej. 123456789">
                </div>
            </div>
            <div class="register-btn-wrapper">
                <button @click="_registerUser()" class="register-btn"> Registrarme </button>
            </div>
            <div class="back-btn-wrapper">
                <a href="/user/login"> 
                    <span class="material-symbols-outlined">chevron_left</span>
                    Volver
                </a>
            </div>
            <div v-if="registration_success" class="success-registration-container">
                <p class="success-registration-text">
                    Se ha registrado con éxito. Revise la bandeja de entrada de su correo electrónico y verifique su cuenta. <br>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'RegisterForm',
    components: {
    },
    data(){
        return{
            user_name: '',
            user_email: '',
            user_phone: '',
            user_password: '',
            web_host: '',
            registration_success: false,
        }
    },
    created(){
        var isMenuSet = setTimeout(() => {
            if(document.getElementById('menu-container') != undefined){
                this._removePrimaryMenu();
                clearInterval(isMenuSet);
            }
        }, 50);
    },
    mounted(){
        this.web_host = this.$detectEnvironment();
    },
    computed: {

    },
    methods: {
        _removePrimaryMenu(){
            document.getElementById('menu-container').style.display = "none";
        },

        _registerUser(){
            if(document.getElementById("username-input").value == "" || document.getElementById("email-input").value == "" || document.getElementById("phone-input").value == "" || document.getElementById("password-input").value == ""){
                this.$vueOnToast.pop('error', "Por favor, rellene todos los campos.");
            } else{
                let url = '/api/v1/users/user/user-register-drupal';
                const success = response => {
                    if(response.data.success) {
                        this.user_name = '';
                        this.user_email = '';
                        this.user_phone = '';
                        this.user_password = '';
                        this.registration_success = true;
                        this.$vueOnToast.pop('success', response.data.response.message);
                    }
                    else {
                        this.user_name = '';
                        this.user_email = '';
                        this.user_phone = '';
                        this.user_password = '';
                        this.$vueOnToast.pop('error', response.data.error);
                    }
                }
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('353', 'Error en el registro');
                const params = new URLSearchParams;
                params.append('user_name', this.user_name);
                params.append('user_email', this.user_email);
                params.append('user_phone', this.user_phone);
                params.append('user_password', this.user_password);
                params.append('web_host', this.web_host);
                const apiCallConfig = {timeoutMs: 0};

                this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);
            }
        }
    },
}
</script>

<style scoped>
.main-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-wrapper{
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 75px 43px 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    width: max-content;
}

.title{
    color: #0F0F1A;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.15px;
    font-weight: 600;
    margin-bottom: 35px;
}

.subtitle{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.email-form-wrapper,
.password-form-wrapper,
.phone-number-form-wrapper{
    margin-top: 20px;
}

.input-wrapper{
    display: flex;
    align-items: center;
}

.register-btn-wrapper{
    margin-top: 40px;
}

.register-btn{
    color: white;
    background-color: #00add9;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    text-transform: uppercase;
    width: 150px;
    height: 40px;
    cursor: pointer;
}

.register-btn:hover{
    background-color: #00a2ca;
}

.register-btn:active{
    transform: translateY(2px);
}

.username-style,
.email-style,
.phone-style,
.password-style{
    border: none !important;
    border-bottom: 1px solid #242632 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 10px 30px !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #242632 !important;
}

.username-style:focus,
.email-style:focus,
.phone-style:focus,
.password-style:focus{
    outline: none;
}

.username-style::placeholder,
.email-style::placeholder,
.phone-style::placeholder{
    font-size: 13px;
}

.back-btn-wrapper{
    margin-top: 20px;
    font-size: 15px;
    color: #00add9;
}

.back-btn-wrapper a{
    display: flex;
}

.success-registration-text{
    color: green;
    font-size: 14px;
}

.success-registration-text a{
    color: #00add9 !important;
}
</style>